var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.skipLoading } },
    [
      _c(
        "body-layout",
        { attrs: { title: "" } },
        [
          _c(
            "div",
            {
              staticClass: "actions flex",
              staticStyle: { "flex-wrap": "wrap", position: "relative" },
              attrs: { slot: "actions" },
              slot: "actions",
            },
            [
              false
                ? _c(
                    "a-button",
                    {
                      staticClass: "mr-3",
                      attrs: { type: "danger" },
                      on: { click: _vm.batchDel },
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e(),
              _c(
                "a-button-group",
                { staticStyle: { display: "flex" } },
                [
                  _c("a-button", {
                    style:
                      _vm.viewType == "list"
                        ? "width:45px;z-index:9"
                        : "width:45px",
                    attrs: {
                      icon: "unordered-list",
                      type: _vm.viewType == "list" ? "primary" : "",
                      ghost: _vm.viewType == "list" ? true : false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeViewType("list")
                      },
                    },
                  }),
                  _c("a-button", {
                    style:
                      _vm.viewType == "calendar" && !_vm.isView
                        ? "width:45px;z-index:9"
                        : "width:45px",
                    attrs: {
                      icon: "table",
                      type:
                        _vm.viewType == "calendar" && !_vm.isView
                          ? "primary"
                          : "",
                      ghost:
                        _vm.viewType == "calendar" && !_vm.isView
                          ? true
                          : false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeViewType("calendar")
                      },
                    },
                  }),
                  _c("a-button", {
                    style:
                      _vm.viewType == "calendar" && _vm.isView
                        ? "width:45px;z-index:9"
                        : "width:45px",
                    attrs: {
                      icon: "calendar",
                      type:
                        _vm.viewType == "calendar" && _vm.isView
                          ? "primary"
                          : "",
                      ghost:
                        _vm.viewType == "calendar" && _vm.isView ? true : false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeViewType("calendar-table")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "0", height: "0", opacity: "0" } },
            [
              _vm.viewType != "list" && _vm.checkedFuncList.indexOf("排期") > -1
                ? _c(
                    "div",
                    { staticClass: "work-left" },
                    [
                      !_vm.workDayValueShow
                        ? _c("div", [
                            _c("span", { staticClass: "work-day" }, [
                              _vm._v("通常可工作日："),
                            ]),
                            _c("span", { staticClass: "work-day-value" }, [
                              _c(
                                "span",
                                { staticClass: "work-day-value" },
                                _vm._l(
                                  Array.from(_vm.workDays || []).sort(
                                    (a, b) => {
                                      return a - b
                                    }
                                  ),
                                  function (item, index) {
                                    return _c("span", { key: item }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            item == 1
                                              ? "周一"
                                              : item == 2
                                              ? "周二"
                                              : item == 3
                                              ? "周三"
                                              : item == 4
                                              ? "周四"
                                              : item == 5
                                              ? "周五"
                                              : item == 6
                                              ? "周六"
                                              : item == 7
                                              ? "周日"
                                              : item == -1
                                              ? "跳过中国法定节假日"
                                              : ""
                                          ) +
                                          _vm._s(
                                            index == _vm.workDays.length - 1
                                              ? ""
                                              : "，"
                                          ) +
                                          "\n              "
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                              _c(
                                "span",
                                [
                                  _vm.labelList.filter(
                                    (item) =>
                                      item.depart.departName.indexOf("日语部") >
                                        -1 ||
                                      item.depart.departName.indexOf("韩语部") >
                                        -1
                                  ).length
                                    ? _c(
                                        "a-popover",
                                        [
                                          _c(
                                            "template",
                                            { slot: "content" },
                                            _vm._l(
                                              _vm.labelList.filter(
                                                (item) =>
                                                  item.depart.departName.indexOf(
                                                    "日语部"
                                                  ) > -1 ||
                                                  item.depart.departName.indexOf(
                                                    "韩语部"
                                                  ) > -1
                                              ),
                                              function (item) {
                                                return _c(
                                                  "p",
                                                  {
                                                    key:
                                                      item.departId +
                                                      "-" +
                                                      item.positionId,
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.position &&
                                                            item.position
                                                              .positionName
                                                            ? (item.depart &&
                                                                item.depart
                                                                  .departName) +
                                                                "-" +
                                                                item.position
                                                                  .positionName
                                                            : ""
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      "：\n                    "
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(item.count)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "work-day-num" },
                                            [_vm._v("可安排工作数量")]
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _c("a-input-number", {
                            ref: "workInput",
                            staticStyle: {
                              width: "200px",
                              "margin-left": "15px",
                            },
                            attrs: { min: 0, placeholder: "工作日可安排数量" },
                            on: { blur: _vm.workEdit },
                            model: {
                              value: _vm.workdayAvailableCount,
                              callback: function ($$v) {
                                _vm.workdayAvailableCount = $$v
                              },
                              expression: "workdayAvailableCount",
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            { staticStyle: { width: "0", height: "0", opacity: "0" } },
            [
              _vm.viewType != "list"
                ? _c(
                    "div",
                    { staticClass: "work-right" },
                    [
                      _c(
                        "a-tooltip",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: {
                            overlayClassName: "work-tooltip",
                            placement: "top",
                          },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(!_vm.isSleep ? "取消请假" : "请假")
                              ),
                            ]),
                          ]),
                          _c("span", {
                            staticClass: "iconfont icon-shangban1 icon",
                            style: _vm.getWorkStyle(),
                            on: {
                              click: function ($event) {
                                _vm.isHoliDay && _vm.isSleep
                                  ? () => {}
                                  : _vm.setVacation(
                                      !_vm.isSleep ? "0" : "HOLIDAY"
                                    )
                              },
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "a-tooltip",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: {
                            overlayClassName: "work-tooltip",
                            placement: "top",
                          },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c("span", [_vm._v("工作")]),
                          ]),
                          _c("span", {
                            staticClass: "iconfont icon-shangban icon",
                            style: _vm.isWorkDay
                              ? "color:rgba(0,0,0,0.25);cursor:no-drop;"
                              : "",
                            on: {
                              click: function ($event) {
                                _vm.isWorkDay
                                  ? () => {}
                                  : _vm.setVacation("WORK")
                              },
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "mytask:list:button:search_user",
                  expression: "'mytask:list:button:search_user'",
                },
              ],
              staticClass: "table-page-search-wrapper",
              attrs: { slot: "search" },
              slot: "search",
            },
            [
              _vm.viewType != "list"
                ? _c(
                    "a-select",
                    {
                      staticStyle: { width: "380px", "margin-right": "15px" },
                      attrs: {
                        mode: "multiple",
                        showArrow: "",
                        placeholder: "选择用户",
                        "option-filter-prop": "label",
                        maxTagCount: 3,
                      },
                      on: { change: _vm.searchQueryForm },
                      model: {
                        value: _vm.userIds,
                        callback: function ($$v) {
                          _vm.userIds = $$v
                        },
                        expression: "userIds",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "suffixIcon" }, slot: "suffixIcon" },
                        [
                          _c(
                            "a-popover",
                            {
                              attrs: {
                                placement: "bottom",
                                overlayClassName: "filter-pop filter-pop-task",
                                trigger: "click",
                              },
                              on: { click: _vm.getFilterTypes },
                              model: {
                                value: _vm.popoverVis,
                                callback: function ($$v) {
                                  _vm.popoverVis = $$v
                                },
                                expression: "popoverVis",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "content" },
                                [
                                  _c(
                                    "a-spin",
                                    { attrs: { spinning: _vm.filterLoading } },
                                    [
                                      _c(
                                        "a-collapse",
                                        {
                                          attrs: { bordered: false },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "expandIcon",
                                                fn: function (props) {
                                                  return [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "caret-right",
                                                        rotate: props.isActive
                                                          ? 90
                                                          : 0,
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            766930589
                                          ),
                                          model: {
                                            value: _vm.activeKey,
                                            callback: function ($$v) {
                                              _vm.activeKey = $$v
                                            },
                                            expression: "activeKey",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-collapse-panel",
                                            {
                                              key: "1",
                                              attrs: { header: "我的收藏" },
                                            },
                                            _vm._l(
                                              _vm.filterList,
                                              function (item, index) {
                                                return _c("p", { key: index }, [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "star-span",
                                                    },
                                                    [
                                                      _c("a-icon", {
                                                        style:
                                                          "color:" + _vm.color,
                                                        attrs: {
                                                          type: "star",
                                                          theme: "filled",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.starClick(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "filter-push-span",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.filterListPush(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(item.name))]
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "filter-div",
                                          staticStyle: {
                                            "padding-left": "0",
                                            "padding-bottom": "0",
                                            "border-bottom":
                                              "1px solid #d9d9d9",
                                            "font-size": "14px",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-row",
                                            {
                                              staticStyle: {
                                                "padding-left": "17px",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-col",
                                                { attrs: { span: 5 } },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "filter-label",
                                                    },
                                                    [_vm._v("阶段：")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "15px",
                                                  },
                                                  attrs: { span: 19 },
                                                },
                                                [
                                                  _c(
                                                    "a-select",
                                                    {
                                                      staticStyle: {
                                                        width: "260px",
                                                      },
                                                      attrs: {
                                                        mode: "multiple",
                                                        showArrow: "",
                                                        placeholder: "选择阶段",
                                                        maxTagCount: 2,
                                                      },
                                                      model: {
                                                        value: _vm.stageIds,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.stageIds = $$v
                                                        },
                                                        expression: "stageIds",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.filterStageList,
                                                      function (stage) {
                                                        return _c(
                                                          "a-select-option",
                                                          {
                                                            key: stage.stageId,
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                stage.stageName
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-row",
                                            {
                                              staticStyle: {
                                                "padding-left": "17px",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-col",
                                                { attrs: { span: 5 } },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "filter-label",
                                                    },
                                                    [_vm._v("作品名称：")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "15px",
                                                  },
                                                  attrs: { span: 19 },
                                                },
                                                [
                                                  _c("a-input", {
                                                    staticStyle: {
                                                      width: "260px",
                                                    },
                                                    attrs: {
                                                      placeholder: "作品名称",
                                                    },
                                                    model: {
                                                      value: _vm.productionName,
                                                      callback: function ($$v) {
                                                        _vm.productionName = $$v
                                                      },
                                                      expression:
                                                        "productionName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-row",
                                            {
                                              staticStyle: {
                                                "padding-left": "17px",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-col",
                                                { attrs: { span: 5 } },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "filter-label",
                                                    },
                                                    [_vm._v("完成状态：")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "15px",
                                                  },
                                                  attrs: { span: 19 },
                                                },
                                                [
                                                  _c(
                                                    "a-select",
                                                    {
                                                      staticStyle: {
                                                        width: "260px",
                                                        position: "relative",
                                                      },
                                                      attrs: {
                                                        showSearch: "",
                                                        allowClear: "",
                                                        showArrow: "",
                                                        placeholder:
                                                          "请选择完成状态",
                                                        mode: "multiple",
                                                        maxTagCount: 2,
                                                        "option-filter-prop":
                                                          "label",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selTaskStatus,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.selTaskStatus =
                                                            $$v
                                                        },
                                                        expression:
                                                          "selTaskStatus",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "FINISHED",
                                                          },
                                                        },
                                                        [_vm._v("已完成")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "STARTED",
                                                          },
                                                        },
                                                        [_vm._v("可开始")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "WAITING",
                                                          },
                                                        },
                                                        [_vm._v("等待中")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "REJECTING",
                                                          },
                                                        },
                                                        [_vm._v("驳回")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-row",
                                            {
                                              staticStyle: {
                                                "padding-left": "17px",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-col",
                                                { attrs: { span: 5 } },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "filter-label",
                                                    },
                                                    [_vm._v("完成情况：")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "15px",
                                                    "line-height": "32px",
                                                  },
                                                  attrs: { span: 19 },
                                                },
                                                [
                                                  _c(
                                                    "a-select",
                                                    {
                                                      staticStyle: {
                                                        width: "260px",
                                                        position: "relative",
                                                      },
                                                      attrs: {
                                                        showSearch: "",
                                                        allowClear: "",
                                                        showArrow: "",
                                                        placeholder:
                                                          "请选择完成情况",
                                                        mode: "multiple",
                                                        maxTagCount: 2,
                                                        "option-filter-prop":
                                                          "label",
                                                      },
                                                      model: {
                                                        value: _vm.finishStatus,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.finishStatus = $$v
                                                        },
                                                        expression:
                                                          "finishStatus",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "ADVANCE",
                                                          },
                                                        },
                                                        [_vm._v("提前")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "ON_TIME",
                                                          },
                                                        },
                                                        [_vm._v("按时")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "DELAY",
                                                          },
                                                        },
                                                        [_vm._v("拖稿")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "filter-div",
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "space-between",
                                            "min-width": "190px",
                                            "font-size": "14px",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-popconfirm",
                                            {
                                              attrs: {
                                                overlayClassName:
                                                  "filter-pop-fileter-name",
                                                "ok-text": "确认",
                                                placement: "bottomLeft",
                                                "cancel-text": "取消",
                                              },
                                              on: { confirm: _vm.addFilter },
                                            },
                                            [
                                              _c("span", {
                                                attrs: { slot: "icon" },
                                                slot: "icon",
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "title" },
                                                  slot: "title",
                                                },
                                                [
                                                  _c("a-input", {
                                                    staticStyle: {
                                                      width: "180px",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        "请输入收藏名称",
                                                    },
                                                    model: {
                                                      value: _vm.filterName,
                                                      callback: function ($$v) {
                                                        _vm.filterName = $$v
                                                      },
                                                      expression: "filterName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "add-filter" },
                                                [_vm._v("+ 收藏当前条件")]
                                              ),
                                            ]
                                          ),
                                          _c("div", [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "add-filter-btn",
                                                staticStyle: {
                                                  color: "rgba(0,0,0,0.65)",
                                                  "margin-right": "5px",
                                                },
                                                on: { click: _vm.removeFilter },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      重置\n                    "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "add-filter-btn",
                                                style:
                                                  "color: #fff;background-color:" +
                                                  _vm.color,
                                                on: {
                                                  click: _vm.searchQueryForm,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      搜索\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "search-user-span",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.openPop.apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v("高级搜索")]
                              ),
                            ],
                            2
                          ),
                          _c("a-icon", {
                            staticClass: "search-user",
                            staticStyle: {
                              "margin-left": "7px",
                              "font-size": "14px",
                            },
                            attrs: { type: "search" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.searchQueryForm.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._l(_vm.allUsers, function (user) {
                        return _c(
                          "a-select-option",
                          { key: user.userId, attrs: { label: user.userName } },
                          [_vm._v(_vm._s(user.userName))]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "a-popover",
                {
                  attrs: {
                    placement: "bottom",
                    overlayClassName: _vm.isAdmin
                      ? "filter-pop status-pop"
                      : "filter-pop status-pop status-user-pop",
                    trigger: "click",
                  },
                  on: { click: _vm.getFilterTypes },
                  model: {
                    value: _vm.statusPopoverVis,
                    callback: function ($$v) {
                      _vm.statusPopoverVis = $$v
                    },
                    expression: "statusPopoverVis",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _c("a-spin", { attrs: { spinning: _vm.filterLoading } }, [
                        _c(
                          "div",
                          { staticClass: "check-list" },
                          [
                            _c(
                              "a-row",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #d9d9d9",
                                  "font-weight": "700",
                                },
                              },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: _vm.isAdmin ? 9 : 12 } },
                                  [
                                    _c(
                                      "a-checkbox",
                                      {
                                        attrs: {
                                          indeterminate:
                                            _vm.indeterminateStatuts,
                                          checked: _vm.checkAllStatus,
                                        },
                                        on: {
                                          change: (e) => {
                                            _vm.onCheckAllChange(e, "status")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    完成状态\n                  "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: _vm.isAdmin ? 15 : 12 } },
                                  [
                                    _c(
                                      "a-checkbox",
                                      {
                                        attrs: {
                                          indeterminate: _vm.indeterminateFunc,
                                          checked: _vm.checkAllFunc,
                                        },
                                        on: {
                                          change: (e) => {
                                            _vm.onCheckAllChange(e, "func")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    日历功能\n                  "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              {
                                staticStyle: {
                                  "border-bottom": "1px solid #d9d9d9",
                                },
                              },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: _vm.isAdmin ? 9 : 12 } },
                                  [
                                    _c("a-checkbox-group", {
                                      attrs: { options: _vm.statusOptions },
                                      on: {
                                        change: (list) => {
                                          _vm.checkChange(list, "status")
                                        },
                                      },
                                      model: {
                                        value: _vm.checkedStatusList,
                                        callback: function ($$v) {
                                          _vm.checkedStatusList = $$v
                                        },
                                        expression: "checkedStatusList",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: _vm.isAdmin ? 15 : 12 } },
                                  [
                                    _c(
                                      "a-checkbox-group",
                                      {
                                        staticStyle: { width: "100%" },
                                        on: {
                                          change: (list) => {
                                            _vm.checkChange(list, "func")
                                          },
                                        },
                                        model: {
                                          value: _vm.checkedFuncList,
                                          callback: function ($$v) {
                                            _vm.checkedFuncList = $$v
                                          },
                                          expression: "checkedFuncList",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-row",
                                          [
                                            _c(
                                              "a-col",
                                              {
                                                attrs: {
                                                  span: _vm.isAdmin ? 13 : 24,
                                                },
                                              },
                                              [
                                                _c(
                                                  "a-checkbox",
                                                  { attrs: { value: "排期" } },
                                                  [
                                                    _vm._v(
                                                      "\n                          排期\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "a-checkbox",
                                                  {
                                                    attrs: { value: "节假日" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          节假日\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "a-checkbox",
                                                  {
                                                    attrs: {
                                                      value: "可安排数量",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          可安排数量\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "filter-div",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "min-width": "190px",
                                  "font-size": "14px",
                                },
                              },
                              [
                                _c("div"),
                                _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "add-filter-btn",
                                      staticStyle: {
                                        color: "rgba(0,0,0,0.65)",
                                        "margin-right": "5px",
                                      },
                                      on: {
                                        click: () => {
                                          this.statusPopoverVis = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    取消\n                  "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "add-filter-btn",
                                      style:
                                        "color: #fff;background-color:" +
                                        _vm.color,
                                      on: { click: _vm.saveFilter },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    确认\n                  "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.viewType != "list"
                    ? _c(
                        "div",
                        {
                          staticClass: "calendar-menu",
                          on: { click: _vm.openStatusPop },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "menu-unfold" } }),
                              _c("span", [_vm._v("功能")]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm.viewType == "list"
                ? _c(
                    "a-select",
                    {
                      staticStyle: { width: "380px" },
                      attrs: {
                        mode: "multiple",
                        showArrow: "",
                        placeholder: "选择用户",
                        "option-filter-prop": "label",
                      },
                      on: { change: _vm.searchQueryForm },
                      model: {
                        value: _vm.userIds,
                        callback: function ($$v) {
                          _vm.userIds = $$v
                        },
                        expression: "userIds",
                      },
                    },
                    _vm._l(_vm.allUsers, function (user) {
                      return _c(
                        "a-select-option",
                        { key: user.userId, attrs: { label: user.userName } },
                        [_vm._v(_vm._s(user.userName))]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("a-table", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.viewType == "list",
                expression: "viewType == 'list'",
              },
            ],
            ref: "table",
            attrs: {
              rowSelection: _vm.rowSelection,
              size: "middle",
              rowKey: "id",
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              customRow: _vm.clickRow,
              pagination: _vm.ipagination,
              loading: _vm.loading,
              scroll: { x: "max-content", y: "calc(100vh - 427px)" },
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "completeStatus",
                fn: function (text, record) {
                  return [
                    _c("actions-tasks", {
                      attrs: {
                        propsParams: record,
                        loadData: _vm.loadData,
                        taskList: _vm.dataSource,
                      },
                    }),
                  ]
                },
              },
              {
                key: "finishStatus",
                fn: function (text, record) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          record.finishStatus == "ADVANCE"
                            ? "提前"
                            : record.finishStatus == "ON_TIME"
                            ? "按时"
                            : record.finishStatus == "DELAY"
                            ? "拖稿"
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "settlementDate",
                fn: function (text) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(text && text.slice(0, 7)) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "action",
                fn: function (text, record) {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return (() => _vm.handleLinkTasks(record)).apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v(" 开始任务 ")]
                    ),
                  ]
                },
              },
              {
                key: "chapterSequenceNo",
                fn: function (text, record) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          parseFloat((record.chapterSequenceNo - 0).toFixed(1))
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "evalResult",
                fn: function (text, record) {
                  return [
                    _vm.kpiViewList(record.errorTypeSumKpiMap).length < 5
                      ? _c(
                          "span",
                          [
                            _vm._l(
                              _vm.kpiViewList(record.errorTypeSumKpiMap),
                              function (item, kpiViewindex) {
                                return [
                                  _c("a-tag", { key: kpiViewindex }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          (item.type == "SOFT"
                                            ? "软伤数"
                                            : item.type == "HARD"
                                            ? "硬伤数"
                                            : item.type == "ERROR"
                                            ? "错翻数"
                                            : item.type == "SUPER_HARD"
                                            ? "超级硬伤数"
                                            : item.type == "V1_HARD"
                                            ? "1改硬伤数"
                                            : item.type == "V2_HARD"
                                            ? "2改硬伤数"
                                            : item.type == "MORE_THAN_V2_HARD"
                                            ? "2改以上硬伤数"
                                            : item.type == "SUPER_SOFT"
                                            ? "超级软伤"
                                            : item.type == "MODIFY" ||
                                              item.type === "null"
                                            ? "修改量"
                                            : "") +
                                            " : " +
                                            item.score
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              }
                            ),
                          ],
                          2
                        )
                      : _c(
                          "a-tooltip",
                          {
                            attrs: { color: "white", placement: "top" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "task-tooltip" },
                                        [
                                          _vm._l(
                                            _vm.kpiViewList(
                                              record.errorTypeSumKpiMap
                                            ),
                                            function (item, kpiViewindex) {
                                              return [
                                                _c(
                                                  "a-tag",
                                                  { key: kpiViewindex },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          (item.type == "SOFT"
                                                            ? "软伤数"
                                                            : item.type ==
                                                              "HARD"
                                                            ? "硬伤数"
                                                            : item.type ==
                                                              "ERROR"
                                                            ? "错翻数"
                                                            : item.type ==
                                                              "SUPER_HARD"
                                                            ? "超级硬伤数"
                                                            : item.type ==
                                                              "V1_HARD"
                                                            ? "1改硬伤数"
                                                            : item.type ==
                                                              "V2_HARD"
                                                            ? "2改硬伤数"
                                                            : item.type ==
                                                              "MORE_THAN_V2_HARD"
                                                            ? "2改以上硬伤数"
                                                            : item.type ==
                                                              "SUPER_SOFT"
                                                            ? "超级软伤"
                                                            : item.type ==
                                                                "MODIFY" ||
                                                              item.type ===
                                                                "null"
                                                            ? "修改量"
                                                            : "") +
                                                            " : " +
                                                            item.score
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "span",
                              [
                                _vm._l(
                                  _vm.kpiViewList(record.errorTypeSumKpiMap),
                                  function (item, kpiViewindex) {
                                    return [
                                      kpiViewindex < 4
                                        ? _c("a-tag", { key: kpiViewindex }, [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  (item.type == "SOFT"
                                                    ? "软伤数"
                                                    : item.type == "HARD"
                                                    ? "硬伤数"
                                                    : item.type == "ERROR"
                                                    ? "错翻数"
                                                    : item.type == "SUPER_HARD"
                                                    ? "超级硬伤数"
                                                    : item.type == "V1_HARD"
                                                    ? "1改硬伤数"
                                                    : item.type == "V2_HARD"
                                                    ? "2改硬伤数"
                                                    : item.type ==
                                                      "MORE_THAN_V2_HARD"
                                                    ? "2改以上硬伤数"
                                                    : item.type == "SUPER_SOFT"
                                                    ? "超级软伤"
                                                    : item.type == "MODIFY" ||
                                                      item.type === "null"
                                                    ? "修改量"
                                                    : "") +
                                                    " : " +
                                                    item.score
                                                ) +
                                                "\n              "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.kpiViewList(record.errorTypeSumKpiMap)
                                        .length > 4
                                        ? "..."
                                        : ""
                                    ) +
                                    "\n          "
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                  ]
                },
              },
            ]),
          }),
          _vm.viewType == "calendar" && _vm.otherShow
            ? _c("panel-calendar", {
                ref: "calendarPanel",
                attrs: {
                  getWorkNum: _vm.getWorkNum,
                  workDays: _vm.workDays,
                  filterCheckList: _vm.filterCheckList,
                  calendarType: _vm.calendarType,
                  workdayAvailableCount: _vm.workdayAvailableCount,
                  userIds: _vm.userIds,
                  isAdmin: _vm.isAdmin,
                  isView: _vm.isView,
                  userInfo: _vm.userInfo,
                  isSkip: _vm.isSkip,
                  isMyTask: true,
                  selectKeys: _vm.selectKeys,
                  queryParam: _vm.queryParam,
                },
                on: {
                  setIsWorkEdit: _vm.setIsWorkEdit,
                  setQueryParam: _vm.setQueryParam,
                  getTitle: _vm.getTitle,
                  setSkipLoading: _vm.setSkipLoading,
                  changeViewTypeToWeek: _vm.changeViewTypeToWeek,
                  getDate: _vm.getDate,
                  getKey: _vm.getKey,
                  setSearchDate: _vm.setSearchDate,
                },
              })
            : _vm._e(),
          _c("term-list-modal", { ref: "termListModal" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }