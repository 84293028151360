<template>
  <a-spin :spinning="skipLoading">
    <body-layout title="">
      <div class="actions flex" style="flex-wrap:wrap;position: relative;" slot="actions">
        <a-button type="danger" class="mr-3" @click="batchDel" v-if="false">删除</a-button>
        <!-- <a-button type="primary" class="mr-3" @click="openAddForm">新建</a-button> -->
        <!-- <a-button type="primary" @click="handleLinkTranslateMytasks" v-has="'mytask:list:button:batch_down'"> -->

        <!--       <a-button icon="download" class="mr-3" @click="handleLinkTranslateLocalDownPage" v-has="'mytask:list:button:batch_download_local'">
      </a-button>

      <a-button icon="upload" class="mr-3" @click="handleLinkTranslateUploadServerPage" v-has="'mytask:list:button:batch_upload_server'">
      </a-button>

      <a-button icon="cloud-download" class="mr-3" @click="handleLinkTranslateMyDowntasks" v-has="'mytask:list:button:batch_download'">
      </a-button>
      <a-button icon="cloud-upload" class="mr-3" @click="handleLinkTranslateMytasks" v-has="'mytask:list:button:batch_upload'">
      </a-button> -->
        <!-- <a-button icon="appstore" class="mr-3" @click="changeViewType('kanban')" :type="viewType == 'kanban' ? 'primary' : ''" :ghost="viewType == 'kanban' ? true : false"></a-button> -->
        <a-button-group style="display:flex">
          <a-button
            icon="unordered-list"
            @click="changeViewType('list')"
            :style="viewType == 'list' ? 'width:45px;z-index:9' : 'width:45px'"
            :type="viewType == 'list' ? 'primary' : ''"
            :ghost="viewType == 'list' ? true : false"
          ></a-button>
          <a-button
            icon="table"
            @click="changeViewType('calendar')"
            :style="viewType == 'calendar' && !isView ? 'width:45px;z-index:9' : 'width:45px'"
            :type="viewType == 'calendar' && !isView ? 'primary' : ''"
            :ghost="viewType == 'calendar' && !isView ? true : false"
          ></a-button>
          <a-button
            :icon="'calendar'"
            @click="changeViewType('calendar-table')"
            :style="viewType == 'calendar' && isView ? 'width:45px;z-index:9' : 'width:45px'"
            :type="viewType == 'calendar' && isView ? 'primary' : ''"
            :ghost="viewType == 'calendar' && isView ? true : false"
          ></a-button>
        </a-button-group>
        <!--         <div style=" position: absolute;bottom: 0;right: 0;width:170px;display: flex;justify-content: end;">
          <a-button v-if="viewType == 'calendar'" style="margin-left:10px" type="default" @click="changeSelect">{{
            isCalendarCancel ? '取消' : '多选'
          }}</a-button>
          <a-dropdown
            :visible="visible"
            :disabled="selectKeys.length == 0"
            @visibleChange="visibleChange"
            :trigger="['click']"
            v-if="viewType == 'calendar'"
            style="margin-left:12px"
          >
            <a-button @click.stop="getRejectStages">完成情况</a-button>
            <a-menu slot="overlay" v-if="selectKeys.length > 0">
              <a-menu-item @click="handleTaskFine()">
                <div>
                  <span class="circular" style="background: green"> </span>
                  <span>已完成</span>
                </div>
              </a-menu-item>
              <a-menu-item
                v-if="
                  selectKeys[0].taskStatus !== '驳回' && selectKeys[0].taskName !== '给稿' && rejectStages.length > 0
                "
              >
                <a-dropdown :trigger="['click']">
                  <div>
                    <span class="circular" style="background: orange"> </span>
                    <span>驳回</span>
                  </div>
                  <div
                    slot="overlay"
                    style=" position: absolute; top: -3.6vh; left: 4.2vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                  >
                    <a-menu v-for="item in rejectStages" :key="item.stageId">
                      <a-menu-item style="height:30px;" @click="handleTasks(item.stageId, 'MIDDLE')">
                        <a-dropdown>
                          <div style="line-height:30px;">{{ item.stageName }}</div>
                          <div
                            slot="overlay"
                            style=" position: absolute; top: -4vh; left: 2.6vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                          >
                            <a-menu>
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'HIGH')"
                                >高</a-menu-item
                              >
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'MIDDLE')"
                                >普通</a-menu-item
                              >
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'LOW')"
                              >
                                低</a-menu-item
                              >
                            </a-menu>
                          </div>
                        </a-dropdown>
                      </a-menu-item>
                    </a-menu>
                  </div>
                </a-dropdown>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-dropdown
            :visible="visible"
            :disabled="selectedRowKeys.length == 0"
            @visibleChange="visibleChange"
            :trigger="['click']"
            v-if="viewType == 'list'"
            style="margin-left:12px"
          >
            <a-button @click.stop="getRejectStages">完成情况</a-button>
            <a-menu slot="overlay" v-if="selectedRowKeys.length > 0">
              <a-menu-item @click="handleTaskFine()">
                <div>
                  <span class="circular" style="background: green"> </span>
                  <span>已完成</span>
                </div>
              </a-menu-item>
              <a-menu-item
                v-if="
                  selectionRows[0].taskStatus !== '驳回' &&
                    selectionRows[0].taskName !== '给稿' &&
                    rejectStages.length > 0
                "
              >
                <a-dropdown :trigger="['click']">
                  <div>
                    <span class="circular" style="background: orange"> </span>
                    <span>驳回</span>
                  </div>
                  <div
                    slot="overlay"
                    style=" position: absolute; top: -3.6vh; left: 4.2vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                  >
                    <a-menu v-for="item in rejectStages" :key="item.stageId">
                      <a-menu-item style="height:30px;" @click="handleTasks(item.stageId, 'MIDDLE')">
                        <a-dropdown>
                          <div style="line-height:30px;">{{ item.stageName }}</div>
                          <div
                            slot="overlay"
                            style=" position: absolute; top: -4vh; left: 2.6vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                          >
                            <a-menu>
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'HIGH')"
                                >高</a-menu-item
                              >
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'MIDDLE')"
                                >普通</a-menu-item
                              >
                              <a-menu-item
                                style="height:30px;line-height:30px;"
                                @click="handleTasks(item.stageId, 'LOW')"
                              >
                                低</a-menu-item
                              >
                            </a-menu>
                          </div>
                        </a-dropdown>
                      </a-menu-item>
                    </a-menu>
                  </div>
                </a-dropdown>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div> -->
        <!-- <div style="width:100%;position: relative;">
        <a-button icon="calendar" @click="changeView" style="position:absolute;right:0;top:-11px;"  v-if="viewType == 'calendar'"></a-button>
      </div> -->
      </div>
      <!--       <div class="table-page-search-wrapper" slot="search" v-has="'mytask:list:button:search_user'">
        <a-form layout="inline" @keyup.enter.native="searchQueryForm" v-has="'production:list:button:search'">
          <a-row type="flex" :gutter="16">
            <a-col>
              <a-select
                mode="multiple"
                showArrow
                placeholder="选择阶段"
                v-model="stageIds"
                style="width: 200px"
                @change="searchQueryForm"
              >
                <a-select-option v-for="stage in stageList" :key="stage.stageId">
                  {{ stage.stageName }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-input placeholder="作品名称" v-model="productionName" style="width: 200px" @change="searchQueryForm" />
            </a-col>
            <a-col>
              <a-month-picker
                style="width:200px;"
                format="YYYY-MM-DD"
                v-model="settlementMonth"
                placeholder="请选择结算月"
                @change="searchQueryForm"
              />
            </a-col>
          </a-row>
        </a-form>
        <a-row v-if="viewType == 'calendar'" type="flex" :gutter="16" style="margin-top: 8px">
          <a-col>
            <a-select
              v-if="isWorkEdit"
              mode="multiple"
              placeholder="通常每周可工作日"
              style="width: 416px"
              v-model="workDays"
              @change="workDaysChange"
            >
              <a-select-option :value="1" :disabled="selectDis">周一</a-select-option>
              <a-select-option :value="2" :disabled="selectDis">周二</a-select-option>
              <a-select-option :value="3" :disabled="selectDis">周三</a-select-option>
              <a-select-option :value="4" :disabled="selectDis">周四</a-select-option>
              <a-select-option :value="5" :disabled="selectDis">周五</a-select-option>
              <a-select-option :value="6" :disabled="selectDis">周六</a-select-option>
              <a-select-option :value="7" :disabled="selectDis">周日</a-select-option>
              <a-select-option :value="-1">跳过中国法定节假日</a-select-option>
            </a-select>
            <div v-else style="width: 416px;font-size:0.9rem;line-height:30px;">
              <span>通常每周可工作日：</span>
              <a-tag
                v-for="(item, index) in Array.from(workDays || []).sort((a, b) => {
                  return a - b
                })"
                :key="item"
              >
                {{
                  item == 1
                    ? '周一'
                    : item == 2
                    ? '周二'
                    : item == 3
                    ? '周三'
                    : item == 4
                    ? '周四'
                    : item == 5
                    ? '周五'
                    : item == 6
                    ? '周六'
                    : item == 7
                    ? '周日'
                    : item == -1
                    ? '跳过中国法定节假日'
                    : ''
                }}
              </a-tag>
            </div>
          </a-col>
          <a-col>
            <a-input-number
              v-if="isWorkEdit"
              :min="0"
              style="width: 200px"
              placeholder="工作日可安排数量"
              v-model="workdayAvailableCount"
              @change="workCountChange"
            />
            <div v-else style="width: 200px;font-size:0.9rem;line-height:30px;">
              <span>工作日可安排数量：</span>
              <span>
                {{ workdayAvailableCount }}
              </span>
            </div>
          </a-col>
          <a-col>
            <a-button
              style="margin-right:10px"
              v-if="this.isWorkEdit"
              @click="
                () => {
                  this.isWorkEdit = !this.isWorkEdit
                }
              "
              >取消</a-button
            >
            <a-button v-if="!isAdmin" type="primary" @click="workEdit">{{
              !isWorkEdit ? '修改排期' : '保存'
            }}</a-button>
            <a-button v-else type="primary" @click="workEdit">{{ !isWorkEdit ? '修改排期' : '保存' }}</a-button>
          </a-col>
          <a-col v-if="viewType == 'calendar'" style="display: flex;">
          </a-col>
        </a-row>
        <a-row type="flex" v-if="viewType != 'kanban'" :gutter="16" style="margin-top: 8px">
          <a-col>
            <div class="flex-1" style="margin-top:17px;">
              <a-radio-group default-value="0" button-style="solid" v-model="taskStatus" @change="searchQueryForm">
                <a-radio-button value="0"> 所有 </a-radio-button>
                <a-radio-button value="1"> 等待 </a-radio-button>
                <a-radio-button value="2"> 可开始 </a-radio-button>
                <a-radio-button value="3"> 已完成 </a-radio-button>
                <a-radio-button value="4"> 驳回 </a-radio-button>
              </a-radio-group>
            </div>
          </a-col>
        </a-row>
      </div> -->
      <div style="width: 0; height: 0; opacity: 0">
        <div v-if="viewType != 'list' && checkedFuncList.indexOf('排期') > -1" class="work-left">
          <div v-if="!workDayValueShow">
            <span class="work-day">通常可工作日：</span>
            <span class="work-day-value">
              <span class="work-day-value">
                <span
                  v-for="(item, index) in Array.from(workDays || []).sort((a, b) => {
                    return a - b
                  })"
                  :key="item"
                >
                  {{
                    item == 1
                      ? '周一'
                      : item == 2
                      ? '周二'
                      : item == 3
                      ? '周三'
                      : item == 4
                      ? '周四'
                      : item == 5
                      ? '周五'
                      : item == 6
                      ? '周六'
                      : item == 7
                      ? '周日'
                      : item == -1
                      ? '跳过中国法定节假日'
                      : ''
                  }}{{ index == workDays.length - 1 ? '' : '，' }}
                </span>
              </span>
              <span>
                <a-popover
                  v-if="
                    labelList.filter(
                      item =>
                        item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
                    ).length
                  "
                >
                  <template slot="content">
                    <p
                      v-for="item in labelList.filter(
                        item =>
                          item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
                      )"
                      :key="item.departId + '-' + item.positionId"
                    >
                      <span>{{
                        item.position && item.position.positionName
                          ? (item.depart && item.depart.departName) + '-' + item.position.positionName
                          : ''
                      }}</span
                      >：
                      <span>{{ item.count }}</span>
                    </p>
                  </template>
                  <span class="work-day-num">可安排工作数量</span>
                </a-popover>
              </span>
            </span>
          </div>
          <!-- 
          <a-select
            v-else
            style="width: 416px"
            ref="workSelect"
            mode="multiple"
            placeholder="通常每周可工作日"
            v-model="workDays"
            @change="workDaysChange"
            @blur="workEdit"
          >
            <a-select-option :value="1" :disabled="selectDis">周一</a-select-option>
            <a-select-option :value="2" :disabled="selectDis">周二</a-select-option>
            <a-select-option :value="3" :disabled="selectDis">周三</a-select-option>
            <a-select-option :value="4" :disabled="selectDis">周四</a-select-option>
            <a-select-option :value="5" :disabled="selectDis">周五</a-select-option>
            <a-select-option :value="6" :disabled="selectDis">周六</a-select-option>
            <a-select-option :value="7" :disabled="selectDis">周日</a-select-option>
            <a-select-option :value="-1">跳过中国法定节假日</a-select-option>
          </a-select> -->
          <a-input-number
            v-else
            :min="0"
            ref="workInput"
            style="width: 200px; margin-left: 15px"
            placeholder="工作日可安排数量"
            v-model="workdayAvailableCount"
            @blur="workEdit"
          />
        </div>
      </div>
      <div style="width:0;height:0;opacity: 0;">
        <div v-if="viewType != 'list'" class="work-right">
          <a-tooltip overlayClassName="work-tooltip" placement="top" style="margin-left:5px">
            <template slot="title">
              <span>{{ !isSleep ? '取消请假' : '请假' }}</span>
            </template>
            <span
              class="iconfont icon-shangban1 icon"
              :style="getWorkStyle()"
              @click="isHoliDay && isSleep ? () => {} : setVacation(!isSleep ? '0' : 'HOLIDAY')"
            ></span>
          </a-tooltip>
          <a-tooltip overlayClassName="work-tooltip" placement="top" style="margin-left:15px">
            <template slot="title">
              <span>工作</span>
            </template>
            <span
              class="iconfont icon-shangban icon"
              :style="isWorkDay ? 'color:rgba(0,0,0,0.25);cursor:no-drop;' : ''"
              @click="isWorkDay ? () => {} : setVacation('WORK')"
            ></span>
          </a-tooltip>
        </div>
      </div>
      <!--       <div v-if="isDesktop()" class="table-page-search-wrapper" slot="search" v-has="'mytask:list:button:search_user'">
        <a-form layout="inline" @keyup.enter.native="searchQueryForm" v-has="'production:list:button:search'">
          <a-row type="flex" :gutter="16">
            <a-col v-has="'myTasks::button:search'">
              <a-select
                v-if="!isSkip"
                mode="multiple"
                showArrow
                placeholder="选择用户"
                option-filter-prop="label"
                v-model="userIds"
                style="width: 200px"
                @change="searchQueryForm"
              >
                <a-select-option :label="user.userName" v-for="user in allUsers" :key="user.userId">
                  {{ user.userName }}
                </a-select-option>
              </a-select>
              <div v-if="isSkip" style="width:200px;">
                <a-button @click="backSkip" type="default">返回</a-button>
              </div>
            </a-col>
            <a-col>
              <a-select
                mode="multiple"
                showArrow
                placeholder="选择阶段"
                v-model="stageIds"
                style="width: 200px"
                @change="searchQueryForm"
              >
                <a-select-option v-for="stage in stageList" :key="stage.stageId">
                  {{ stage.stageName }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-input placeholder="作品名称" v-model="productionName" style="width: 200px" @change="searchQueryForm" />
            </a-col>
            <a-col>
              <a-month-picker
                style="width:200px;"
                format="YYYY-MM"
                v-model="settlementMonth"
                placeholder="请选择结算月"
                @change="searchQueryForm"
              />
            </a-col>
            <a-col v-has="'myTasks::button:search'">
              <a-select
                showSearch
                allowClear
                showArrow
                placeholder="选择人员日历"
                v-model="skipUserId"
                option-filter-prop="label"
                style="width: 200px;position: relative;"
                @change="toOtherCalender"
              >
                <a-select-option v-for="user in allUsers" :label="user.userName" :key="user.userId">
                  <div
                    style="width: 100%;line-height:30px;display: inline-block;color:rgba(0,0,0,0.65);display: flex;justify-content: center;align-items: center;"
                    target="_blank"
                  >
                    <img style="width:30px;height:30px;border-radius:50%;" :src="user.avatar" alt="" />
                    <div style="width:100px">{{ user.userName }}</div>
                  </div>
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </a-form>
        <a-row v-if="viewType == 'calendar'" type="flex" :gutter="16" style="margin-top: 8px">
          <a-col v-if="viewType == 'calendar'">
            <a-select
              v-if="!isAdmin"
              :disabled="!dateInfo || isAllDis"
              placeholder="请选择工作状态"
              style="width: 200px"
              v-model="vacation"
              @change="setVacation"
            >
              <a-select-option :disabled="isHoliDay" value="HOLIDAY">我要请假</a-select-option>
              <a-select-option :disabled="isSleep" value="0">取消请假</a-select-option>
              <a-select-option :disabled="isWorkDay" value="WORK">我要工作</a-select-option>
            </a-select>
            <a-select
              v-else
              :disabled="!dateInfo || isAllDis"
              placeholder="请选择工作状态"
              style="width: 200px"
              v-model="vacation"
              @change="setVacation"
            >
              <a-select-option :disabled="isHoliDay" value="HOLIDAY">我要请假</a-select-option>
              <a-select-option :disabled="isSleep" value="0">取消请假</a-select-option>
              <a-select-option :disabled="isWorkDay" value="WORK">我要工作</a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select
              v-if="isWorkEdit"
              mode="multiple"
              placeholder="通常每周可工作日"
              style="width: 416px"
              v-model="workDays"
              @change="workDaysChange"
            >
              <a-select-option :value="1" :disabled="selectDis">周一</a-select-option>
              <a-select-option :value="2" :disabled="selectDis">周二</a-select-option>
              <a-select-option :value="3" :disabled="selectDis">周三</a-select-option>
              <a-select-option :value="4" :disabled="selectDis">周四</a-select-option>
              <a-select-option :value="5" :disabled="selectDis">周五</a-select-option>
              <a-select-option :value="6" :disabled="selectDis">周六</a-select-option>
              <a-select-option :value="7" :disabled="selectDis">周日</a-select-option>
              <a-select-option :value="-1">跳过中国法定节假日</a-select-option>
            </a-select>
            <div v-else style="width: 416px;font-size:0.9rem;line-height:30px;">
              <span>通常每周可工作日：</span>
              <a-tag
                v-for="item in Array.from(workDays || []).sort((a, b) => {
                  return a - b
                })"
                :key="item"
              >

              
              </a-tag>
            </div>
          </a-col>
          <a-col>
            <a-input-number
              v-if="isWorkEdit"
              :min="0"
              style="width: 200px"
              placeholder="工作日可安排数量"
              v-model="workdayAvailableCount"
              @change="workCountChange"
            />
            <div v-else style="width: 200px;font-size:0.9rem;line-height:30px;">
              <span>工作日可安排数量：</span>
              <span>
                {{ workdayAvailableCount }}
              </span>
            </div>
          </a-col>
          <a-col>
            <a-button
              style="margin-right:10px"
              v-if="this.isWorkEdit"
              @click="
                () => {
                  this.isWorkEdit = !this.isWorkEdit
                }
              "
              >取消</a-button
            >
            <a-button v-if="!isAdmin" type="primary" @click="workEdit">{{
              !isWorkEdit ? '修改排期' : '保存'
            }}</a-button>
            <a-button v-else type="primary" @click="workEdit">{{ !isWorkEdit ? '修改排期' : '保存' }}</a-button>
          </a-col>
          <a-col v-if="viewType == 'calendar'" style="display: flex;">
          </a-col>
        </a-row>
        <a-row type="flex" v-if="viewType != 'kanban'" :gutter="16" style="margin-top: 8px">
          <a-col>
            <div class="flex-1" style="margin-top:17px;">
              <a-radio-group default-value="0" button-style="solid" v-model="taskStatus" @change="searchQueryForm">
                <a-radio-button value="0"> 所有 </a-radio-button>
                <a-radio-button value="1"> 等待 </a-radio-button>
                <a-radio-button value="2"> 可开始 </a-radio-button>
                <a-radio-button value="3"> 已完成 </a-radio-button>
                <a-radio-button value="4"> 驳回 </a-radio-button>
              </a-radio-group>
            </div>
          </a-col>
        </a-row>
      </div>-->
      <div class="table-page-search-wrapper" slot="search" v-has="'mytask:list:button:search_user'">
        <a-select
          v-if="viewType != 'list'"
          mode="multiple"
          showArrow
          placeholder="选择用户"
          option-filter-prop="label"
          :maxTagCount="3"
          v-model="userIds"
          style="width: 380px;margin-right:15px;"
          @change="searchQueryForm"
        >
          <span slot="suffixIcon">
            <a-popover
              placement="bottom"
              v-model="popoverVis"
              overlayClassName="filter-pop filter-pop-task"
              @click="getFilterTypes"
              trigger="click"
            >
              <template slot="content">
                <a-spin :spinning="filterLoading">
                  <a-collapse v-model="activeKey" :bordered="false">
                    <template #expandIcon="props">
                      <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                    </template>
                    <a-collapse-panel key="1" header="我的收藏">
                      <p v-for="(item, index) in filterList" :key="index">
                        <span class="star-span">
                          <a-icon @click="starClick(item)" :style="'color:' + color" type="star" theme="filled" />
                        </span>
                        <span class="filter-push-span" @click="filterListPush(item)">{{ item.name }}</span>
                      </p>
                    </a-collapse-panel>
                  </a-collapse>
                  <div
                    class="filter-div"
                    style="padding-left:0;padding-bottom:0;border-bottom: 1px solid #d9d9d9;font-size:14px;"
                  >
                    <!--                     <a-row style="padding-left:17px" v-has="'myTasks::button:search'">
                      <a-col :span="5">
                        <span class="filter-label">人员昵称：</span>
                      </a-col>
                      <a-col :span="19" style="padding-left:15px">
                        <a-select
                          v-if="!isSkip"
                          mode="multiple"
                          showArrow
                          placeholder="请选择/输入人名"
                          option-filter-prop="label"
                          :maxTagCount="2"
                          v-model="userIds"
                          style="width: 260px"
                        >
                          <a-select-option :label="user.userName" v-for="user in allUsers" :key="user.userId">{{
                            user.userName
                          }}</a-select-option>
                        </a-select>
                      </a-col>
                    </a-row> -->
                    <a-row style="padding-left:17px">
                      <a-col :span="5">
                        <span class="filter-label">阶段：</span>
                      </a-col>
                      <a-col :span="19" style="padding-left:15px">
                        <a-select
                          mode="multiple"
                          showArrow
                          placeholder="选择阶段"
                          :maxTagCount="2"
                          v-model="stageIds"
                          style="width: 260px"
                        >
                          <a-select-option v-for="stage in filterStageList" :key="stage.stageId">{{
                            stage.stageName
                          }}</a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left:17px">
                      <a-col :span="5">
                        <span class="filter-label">作品名称：</span>
                      </a-col>
                      <a-col :span="19" style="padding-left:15px">
                        <a-input placeholder="作品名称" v-model="productionName" style="width: 260px" />
                      </a-col>
                    </a-row>
                    <a-row style="padding-left:17px">
                      <a-col :span="5">
                        <span class="filter-label">完成状态：</span>
                      </a-col>
                      <a-col :span="19" style="padding-left:15px">
                        <a-select
                          showSearch
                          allowClear
                          showArrow
                          placeholder="请选择完成状态"
                          mode="multiple"
                          :maxTagCount="2"
                          v-model="selTaskStatus"
                          option-filter-prop="label"
                          style="width: 260px;position: relative;"
                        >
                          <a-select-option value="FINISHED">已完成</a-select-option>
                          <a-select-option value="STARTED">可开始</a-select-option>
                          <a-select-option value="WAITING">等待中</a-select-option>
                          <a-select-option value="REJECTING">驳回</a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                    <a-row style="padding-left:17px">
                      <a-col :span="5">
                        <span class="filter-label">完成情况：</span>
                      </a-col>
                      <a-col :span="19" style="padding-left:15px;line-height: 32px;">
                        <a-select
                          showSearch
                          allowClear
                          showArrow
                          placeholder="请选择完成情况"
                          mode="multiple"
                          :maxTagCount="2"
                          v-model="finishStatus"
                          option-filter-prop="label"
                          style="width: 260px;position: relative;"
                        >
                          <a-select-option value="ADVANCE">提前</a-select-option>
                          <a-select-option value="ON_TIME">按时</a-select-option>
                          <a-select-option value="DELAY">拖稿</a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                  </div>
                  <div
                    class="filter-div"
                    style="display: flex; justify-content: space-between;min-width:190px;font-size:14px;"
                  >
                    <a-popconfirm
                      overlayClassName="filter-pop-fileter-name"
                      ok-text="确认"
                      placement="bottomLeft"
                      cancel-text="取消"
                      @confirm="addFilter"
                    >
                      <span slot="icon"></span>
                      <span slot="title">
                        <a-input placeholder="请输入收藏名称" v-model="filterName" style="width: 180px" />
                      </span>
                      <span class="add-filter">+ 收藏当前条件</span>
                    </a-popconfirm>
                    <div>
                      <button
                        class="add-filter-btn"
                        style="color:rgba(0,0,0,0.65);margin-right:5px"
                        @click="removeFilter"
                      >
                        重置
                      </button>
                      <button
                        class="add-filter-btn"
                        :style="'color: #fff;background-color:' + color"
                        @click="searchQueryForm"
                      >
                        搜索
                      </button>
                    </div>
                  </div>
                </a-spin>
              </template>
              <span class="search-user-span" @click.stop="openPop">高级搜索</span>
            </a-popover>
            <a-icon
              class="search-user"
              @click.stop="searchQueryForm"
              style="margin-left: 7px;font-size: 14px;"
              type="search"
            />
          </span>
          <a-select-option :label="user.userName" v-for="user in allUsers" :key="user.userId">{{
            user.userName
          }}</a-select-option>
        </a-select>
        <a-popover
          placement="bottom"
          v-model="statusPopoverVis"
          :overlayClassName="isAdmin ? 'filter-pop status-pop' : 'filter-pop status-pop status-user-pop'"
          @click="getFilterTypes"
          trigger="click"
        >
          <template slot="content">
            <a-spin :spinning="filterLoading">
              <div class="check-list">
                <a-row style="border-bottom:1px solid #d9d9d9;font-weight:700">
                  <a-col :span="isAdmin ? 9 : 12">
                    <a-checkbox
                      :indeterminate="indeterminateStatuts"
                      :checked="checkAllStatus"
                      @change="
                        e => {
                          onCheckAllChange(e, 'status')
                        }
                      "
                    >
                      完成状态
                    </a-checkbox>
                  </a-col>
                  <a-col :span="isAdmin ? 15 : 12">
                    <a-checkbox
                      :indeterminate="indeterminateFunc"
                      :checked="checkAllFunc"
                      @change="
                        e => {
                          onCheckAllChange(e, 'func')
                        }
                      "
                    >
                      日历功能
                    </a-checkbox>
                  </a-col>
                </a-row>
                <a-row style="border-bottom:1px solid #d9d9d9;">
                  <a-col :span="isAdmin ? 9 : 12">
                    <a-checkbox-group
                      v-model="checkedStatusList"
                      :options="statusOptions"
                      @change="
                        list => {
                          checkChange(list, 'status')
                        }
                      "
                    />
                  </a-col>
                  <a-col :span="isAdmin ? 15 : 12">
                    <a-checkbox-group
                      style="width: 100%;"
                      v-model="checkedFuncList"
                      @change="
                        list => {
                          checkChange(list, 'func')
                        }
                      "
                    >
                      <a-row>
                        <a-col :span="isAdmin ? 13 : 24">
                          <a-checkbox value="排期">
                            排期
                          </a-checkbox>
                          <a-checkbox value="节假日">
                            节假日
                          </a-checkbox>
                          <a-checkbox value="可安排数量">
                            可安排数量
                          </a-checkbox>
                        </a-col>
                      </a-row>
                    </a-checkbox-group>
                  </a-col>
                </a-row>
                <div
                  class="filter-div"
                  style="display: flex; justify-content: space-between;min-width:190px;font-size:14px;"
                >
                  <div></div>
                  <div>
                    <button
                      class="add-filter-btn"
                      style="color:rgba(0,0,0,0.65);margin-right:5px"
                      @click="
                        () => {
                          this.statusPopoverVis = false
                        }
                      "
                    >
                      取消
                    </button>
                    <button class="add-filter-btn" :style="'color: #fff;background-color:' + color" @click="saveFilter">
                      确认
                    </button>
                  </div>
                </div>
              </div>
            </a-spin>
          </template>
          <div v-if="viewType != 'list'" @click="openStatusPop" class="calendar-menu">
            <div>
              <a-icon type="menu-unfold" />
              <span>功能</span>
            </div>
          </div>
        </a-popover>
        <a-select
          v-if="viewType == 'list'"
          mode="multiple"
          showArrow
          placeholder="选择用户"
          option-filter-prop="label"
          v-model="userIds"
          style="width: 380px"
          @change="searchQueryForm"
        >
          <a-select-option :label="user.userName" v-for="user in allUsers" :key="user.userId">{{
            user.userName
          }}</a-select-option>
        </a-select>
      </div>
      <a-table
        v-show="viewType == 'list'"
        :rowSelection="rowSelection"
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :customRow="clickRow"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 'max-content', y: 'calc(100vh - 427px)' }"
      >
        <template slot="completeStatus" slot-scope="text, record">
          <actions-tasks :propsParams="record" :loadData="loadData" :taskList="dataSource"></actions-tasks>
        </template>
        <template slot="finishStatus" slot-scope="text, record">
          <span>{{
            record.finishStatus == 'ADVANCE'
              ? '提前'
              : record.finishStatus == 'ON_TIME'
              ? '按时'
              : record.finishStatus == 'DELAY'
              ? '拖稿'
              : ''
          }}</span>
        </template>
        <template slot="settlementDate" slot-scope="text">
          {{ text && text.slice(0, 7) }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click.stop="() => handleLinkTasks(record)"> 开始任务 </a>
        </template>
        <template slot="chapterSequenceNo" slot-scope="text, record">
          {{ parseFloat((record.chapterSequenceNo - 0).toFixed(1)) }}
        </template>
        <template slot="evalResult" slot-scope="text, record">
          <span v-if="kpiViewList(record.errorTypeSumKpiMap).length < 5">
            <template v-for="(item, kpiViewindex) in kpiViewList(record.errorTypeSumKpiMap)">
              <a-tag :key="kpiViewindex">
                {{
                  (item.type == 'SOFT'
                    ? '软伤数'
                    : item.type == 'HARD'
                    ? '硬伤数'
                    : item.type == 'ERROR'
                    ? '错翻数'
                    : item.type == 'SUPER_HARD'
                    ? '超级硬伤数'
                    : item.type == 'V1_HARD'
                    ? '1改硬伤数'
                    : item.type == 'V2_HARD'
                    ? '2改硬伤数'
                    : item.type == 'MORE_THAN_V2_HARD'
                    ? '2改以上硬伤数'
                    : item.type == 'SUPER_SOFT'
                    ? '超级软伤'
                    : item.type == 'MODIFY' || item.type === 'null'
                    ? '修改量'
                    : '') +
                    ' : ' +
                    item.score
                }}
              </a-tag>
            </template>
          </span>
          <a-tooltip v-else color="white" placement="top">
            <template #title>
              <div class="task-tooltip">
                <template v-for="(item, kpiViewindex) in kpiViewList(record.errorTypeSumKpiMap)">
                  <a-tag :key="kpiViewindex">
                    {{
                      (item.type == 'SOFT'
                        ? '软伤数'
                        : item.type == 'HARD'
                        ? '硬伤数'
                        : item.type == 'ERROR'
                        ? '错翻数'
                        : item.type == 'SUPER_HARD'
                        ? '超级硬伤数'
                        : item.type == 'V1_HARD'
                        ? '1改硬伤数'
                        : item.type == 'V2_HARD'
                        ? '2改硬伤数'
                        : item.type == 'MORE_THAN_V2_HARD'
                        ? '2改以上硬伤数'
                        : item.type == 'SUPER_SOFT'
                        ? '超级软伤'
                        : item.type == 'MODIFY' || item.type === 'null'
                        ? '修改量'
                        : '') +
                        ' : ' +
                        item.score
                    }}
                  </a-tag>
                </template>
              </div>
            </template>
            <span>
              <template v-for="(item, kpiViewindex) in kpiViewList(record.errorTypeSumKpiMap)">
                <a-tag v-if="kpiViewindex < 4" :key="kpiViewindex">
                  {{
                    (item.type == 'SOFT'
                      ? '软伤数'
                      : item.type == 'HARD'
                      ? '硬伤数'
                      : item.type == 'ERROR'
                      ? '错翻数'
                      : item.type == 'SUPER_HARD'
                      ? '超级硬伤数'
                      : item.type == 'V1_HARD'
                      ? '1改硬伤数'
                      : item.type == 'V2_HARD'
                      ? '2改硬伤数'
                      : item.type == 'MORE_THAN_V2_HARD'
                      ? '2改以上硬伤数'
                      : item.type == 'SUPER_SOFT'
                      ? '超级软伤'
                      : item.type == 'MODIFY' || item.type === 'null'
                      ? '修改量'
                      : '') +
                      ' : ' +
                      item.score
                  }}
                </a-tag>
              </template>
              {{ kpiViewList(record.errorTypeSumKpiMap).length > 4 ? '...' : '' }}
            </span>
          </a-tooltip>
        </template>
      </a-table>

      <panel-calendar
        @setIsWorkEdit="setIsWorkEdit"
        @setQueryParam="setQueryParam"
        :getWorkNum="getWorkNum"
        :workDays="workDays"
        :filterCheckList="filterCheckList"
        :calendarType="calendarType"
        :workdayAvailableCount="workdayAvailableCount"
        :userIds="userIds"
        :isAdmin="isAdmin"
        @getTitle="getTitle"
        @setSkipLoading="setSkipLoading"
        @changeViewTypeToWeek="changeViewTypeToWeek"
        :isView="isView"
        :userInfo="userInfo"
        @getDate="getDate"
        @getKey="getKey"
        :isSkip="isSkip"
        ref="calendarPanel"
        :isMyTask="true"
        :selectKeys="selectKeys"
        :queryParam="queryParam"
        @setSearchDate="setSearchDate"
        v-if="viewType == 'calendar' && otherShow"
      />
      <term-list-modal ref="termListModal" />
      <!-- <panel-kan-ban :allUsers="allUsers" @getKey="getKey" ref="kanbanPanel" :isMyTask="true" :selectKeys="selectKeys" :queryParam="queryParam" v-if="viewType == 'kanban'" /> -->
    </body-layout>
  </a-spin>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import ActionsTasks from './modules/mytasks/actionsTasks'
import moment from 'moment'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import { createAlink } from '@/utils/util'
import { getAction, postAction, putAction, deleteAction } from '../../api/manage'
import PanelCalendar from '@/views/modules/panel-calendar.vue'
import PanelKanBan from '@/views/modules/panel-kanban.vue'
import { upstatusDownloadFile } from '@/utils/util.js'
import termListModal from '@/components/termListModal'
import { handleArrayParams } from '@/utils/util'
export default {
  mixins: [JeecgListMixin],
  components: {
    ActionsTasks,
    PanelCalendar,
    termListModal,
    PanelKanBan
  },
  data() {
    return {
      checkAllStatus: false,
      checkedFuncList: [],
      checkedStatusList: [],
      labelList: [],
      checkAllFunc: false,
      indeterminateStatuts: false,
      indeterminateFunc: false,
      calendarType: 'dayGridMonth',
      checkedData: {
        checkedFuncList: ['排期', '节假日', '可安排数量'],
        checkedStatusList: ['已完成', '可开始', '等待', '驳回']
      },
      statusOptions: [
        { value: '已完成', label: '已完成' },
        { value: '可开始', label: '可开始' },
        { value: '等待', label: '等待中' },
        { value: '驳回', label: '驳回' }
      ],
      funcOptions: [
        { value: '排期', label: '排期' },
        { value: '节假日', label: '节假日' },
        { value: '可安排数量', label: '可安排数量' }
      ],
      vacation: undefined,
      workDayValueShow: false,
      userInfo: {},
      activeKey: ['1'],
      filterList: [],
      filterLoading: false,
      popoverVis: false,
      statusPopoverVis: false,
      visible: false,
      isView: false,
      isWeek: false,
      calendarDate: '',
      skipUserId: undefined,
      selectKeys: [],
      rejectStages: [],
      selectedRowKeys: [],
      filterCheckList: ['排期', '节假日', '可安排数量', '已完成', '可开始', '等待', '驳回'],
      columns: [
        {
          title: '平台',
          dataIndex: 'platformName',
          width: 120,
          sorter: false
        },

        {
          title: '作品',
          dataIndex: 'productionName',
          width: 200,
          sorter: false
        },
        {
          title: '章节序号',
          dataIndex: 'chapterSequenceNo',
          width: 100,
          sorter: false,
          scopedSlots: { customRender: 'chapterSequenceNo' }
        },
        {
          title: '结算数量',
          dataIndex: 'calcUnits',
          width: 100,
          sorter: false
        },
        {
          title: '阶段',
          dataIndex: 'stageName',
          width: 120,
          sorter: false
        },
        {
          title: '人员',
          dataIndex: 'user.userName',
          width: 100,
          sorter: false
        },
        {
          title: '截止日期',
          dataIndex: 'deadline',
          width: 170,
          sorter: false
        },
        {
          title: '结算月份',
          dataIndex: 'settlementDate',
          width: 100,
          sorter: false,
          scopedSlots: { customRender: 'settlementDate' }
        },
        {
          title: '完成状态',
          // dataIndex: '',
          width: 80,
          sorter: false,
          scopedSlots: { customRender: 'completeStatus' }
        },
        {
          title: '时间间隔',
          dataIndex: 'timeInterval',
          width: 80,
          sorter: false
        },
        /*         {
          title: '优先级',
          dataIndex: 'priority',
          width: 100,
          sorter: false,
        }, */
        {
          title: '完成情况',
          dataIndex: 'finishStatus',
          width: 100,
          sorter: false,
          scopedSlots: { customRender: 'finishStatus' }
        },
        {
          title: '考核结果',
          dataIndex: 'evalResult',
          width: 250,
          sorter: false,
          scopedSlots: { customRender: 'evalResult' }
        },
        {
          title: '考核得分',
          dataIndex: 'evalScore',
          width: 80,
          sorter: false
        }
        /*         {
          title: '操作',
          // dataIndex: '',
          width: 100,
          sorter: false,
          scopedSlots: { customRender: 'action' },
        }, */
      ],
      mytasksDetialPath: '/translate/mytasks/detail',
      mytasksUploadPath: '/translate/mytasks/upload',
      translateMyLocalDownPage: '/translate/mytasks/downLocal',
      translateUploadserver: '/translate/mytasks/uploadServer',
      translateMyDowntasksDetialPath: '/translate/batchdown/index',
      translateMytasksDetialPath: '/translate/mytasks/index',
      translateEditorPath: '/translate/editor',
      url: {
        list: '/tasks/my'
      },
      name: '',
      color: '',
      allUsers: [],
      userIds: [],
      stageIds: [],
      stageList: [],
      taskStatus: '0',
      productionName: '',
      startDate: '',
      endDate: '',
      workDays: [],
      isAdmin: false,
      viewType: 'calendar',
      isSelect: false,
      selectDis: false,
      workdayAvailableCount: 0,
      dateInfo: null,
      settlementMonth: '',
      nowDate: '',
      isWorkEdit: false,
      isSkip: false,
      skipLoading: false,
      isCalendarCancel: false,
      isWorkDay: false,
      isHoliDay: false,
      finishDis: false,
      isSleep: false,
      finishStatus: [],
      selTaskStatus: [],
      filterStageList: [],
      filterName: '',
      otherShow: false
    }
  },
  watch: {
    startDate(startDate) {
      this.queryParam.startDate = startDate?.format('YYYY-MM-DD') || ''
    },
    endDate(endDate) {
      this.queryParam.endDate = endDate?.format('YYYY-MM-DD') || ''
    },
    settlementMonth(settlementMonth) {
      this.queryParam.settlementMonth = settlementMonth?.format('YYYY-MM') || ''
      this.queryParam.settlementMonth = this.queryParam.settlementMonth + '-01'
    },
    userIds() {
      this.getFilterStageList()
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectionRows = selectedRows
          if (this.selectionRows.length == 1) {
            this.dataSource.forEach(item => {
              if (item.taskStatus != this.selectionRows[0].taskStatus) {
                item.disabled = true
              }
            })
            this.dataSource = [...this.dataSource]
          } else {
            this.dataSource.forEach(item => {
              if (item.taskStatus === '等待' && item.taskName != '给稿') {
                item.disabled = false
              }
            })
            this.dataSource = [...this.dataSource]
          }
        },
        getCheckboxProps: record => ({
          props: {
            disabled:
              (record.taskStatus === '等待' && record.taskName != '给稿') || record.taskStatus == '已完成'
                ? true
                : this.selectionRows.length > 0
                ? record.taskStatus != this.selectionRows[0].taskStatus
                  ? true
                  : false
                : false
          }
        })
      }
    }
  },
  methods: {
    moment,
    async getWorkNum() {
      const res = await getAction('/taskSchedule?userId=' + this.$route.params.userId)
      if (res.code == 200) {
        let workMap = {
          MONDAY: 1,
          TUESDAY: 2,
          WEDNESDAY: 3,
          THURSDAY: 4,
          FRIDAY: 5,
          SATURDAY: 6,
          SUNDAY: 7,
          WORK_DAY: -1
        }
        console.log(this.userInfo)
        if (this.userInfo.userFile.scheduleIsDaily) {
          res.data.forEach(item => {
            if (
              item.week == 'WORK_DAY' &&
              this.labelList.findIndex(
                i =>
                  i.position.positionName == item.position.positionName && i.depart.departName == item.depart.departName
              ) == -1
            ) {
              this.labelList.push(item)
            }
          })
        } else {
          this.labelList = res.data.filter(item => this.workDays.some(i => i == workMap[item.week]))
        }
      }
    },
    async saveFilter() {
      this.filterLoading = true
      const data = {
        name: 'check',
        content: JSON.stringify({
          checkedFuncList: this.checkedFuncList,
          checkedStatusList: this.checkedStatusList
        }),
        domain: 'myTsskFunc',
        type: 'FILTER'
      }
      await postAction('/fontQueryCondition', [data])
      await deleteAction(
        '/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids: [this.checkedData.id || ''] })).toString()
      )
      this.filterCheckList = [...this.checkedFuncList, ...this.checkedStatusList]
      this.statusPopoverVis = false
      if (
        JSON.stringify(this.checkedFuncList) != JSON.stringify(JSON.parse(this.checkedData.content).checkedFuncList)
      ) {
        window.location.reload()
      } else {
        this.$refs.calendarPanel.reloadData()
      }
    },
    checkChange(checkedList, type) {
      let statusOptions = ['已完成', '可开始', '等待', '驳回']
      let funcOptions = []
      funcOptions = ['排期', '节假日', '可安排数量']
      if (type == 'status') {
        this.indeterminateStatuts = !!checkedList.length && checkedList.length < statusOptions.length
        this.checkAllStatus = checkedList.length === statusOptions.length
      } else {
        this.indeterminateFunc = !!checkedList.length && checkedList.length < funcOptions.length
        this.checkAllFunc = checkedList.length === funcOptions.length
      }
    },
    onCheckAllChange(e, type) {
      let statusOptions = ['已完成', '可开始', '等待', '驳回']
      let funcOptions = []
      funcOptions = ['排期', '节假日', '可安排数量']
      if (type == 'status') {
        Object.assign(this, {
          checkedStatusList: e.target.checked ? statusOptions : [],
          indeterminateStatuts: false,
          checkAllStatus: e.target.checked
        })
      } else {
        Object.assign(this, {
          checkedFuncList: e.target.checked ? funcOptions : [],
          indeterminateFunc: false,
          checkAllFunc: e.target.checked
        })
      }
    },
    async getCheckList() {
      const res = await getAction('/fontQueryCondition', { domain: 'myTsskFunc', type: 'FILTER' })
      try {
        this.checkedData = res.data[res.data.length - 1] || this.checkedData
        if (res.data[res.data.length - 1]) {
          this.checkedFuncList = JSON.parse(this.checkedData.content).checkedFuncList
          this.checkedStatusList = JSON.parse(this.checkedData.content).checkedStatusList
          this.filterCheckList = [...this.checkedFuncList, ...this.checkedStatusList]
        } else {
          this.checkedFuncList = this.checkedData.checkedFuncList
          this.checkedStatusList = this.checkedData.checkedStatusList
          this.filterCheckList = [...this.checkedFuncList, ...this.checkedStatusList]
        }
      } catch (err) {}
    },
    async openStatusPop() {
      this.filterLoading = true
      this.statusPopoverVis = true
      let statusOptions = ['已完成', '可开始', '等待', '驳回']
      let funcOptions = []
      funcOptions = ['排期', '节假日', '可安排数量']
      const res = await getAction('/fontQueryCondition', { domain: 'myTsskFunc', type: 'FILTER' })
      this.checkedData = res.data[res.data.length - 1] || this.checkedData
      if (res.data[res.data.length - 1]) {
        this.checkedFuncList = JSON.parse(this.checkedData.content).checkedFuncList
        this.checkedStatusList = JSON.parse(this.checkedData.content).checkedStatusList
      } else {
        this.checkedFuncList = this.checkedData.checkedFuncList
        this.checkedStatusList = this.checkedData.checkedStatusList
      }
      this.indeterminateFunc = this.checkedFuncList.length < funcOptions.length
      this.checkAllFunc = funcOptions.length === this.checkedFuncList.length
      this.indeterminateStatuts = this.checkedStatusList.length < statusOptions.length
      this.checkAllStatus = statusOptions.length === this.checkedStatusList.length
      this.filterLoading = false
    },
    getWorkStyle() {
      let text = 'color:rgba(0,0,0,0.65)'
      if (!this.dateInfo) return text
      if (!this.isSleep) {
        return 'color:' + this.color
      }
      if (this.isHoliDay) {
        return 'color:rgba(0,0,0,0.25);cursor:no-drop;'
      }
    },
    radioChange(e) {},
    async getFilterStageList() {
      const res = await getAction(
        '/stage/stageByUserIds?' + new URLSearchParams(handleArrayParams({ userIds: this.userIds })).toString()
      )
      if (res.code == 200) {
        this.filterStageList = res.data
      }
    },
    filterListPush(item) {
      JSON.parse(item.content).userIds && (this.userIds = JSON.parse(item.content).userIds)
      JSON.parse(item.content).stageIds && (this.stageIds = JSON.parse(item.content).stageIds)
      JSON.parse(item.content).productionName && (this.productionName = JSON.parse(item.content).productionName)
      JSON.parse(item.content).selTaskStatus && (this.selTaskStatus = JSON.parse(item.content).selTaskStatus)
      JSON.parse(item.content).finishStatus && (this.finishStatus = JSON.parse(item.content).finishStatus)
    },
    async removeFilter() {
      const { id } = Vue.ls.get(USER_INFO) || {}
      this.userIds = [id]
      this.stageIds = []
      this.productionName = ''
      this.selTaskStatus = []
      this.finishStatus = []
    },
    async addFilter() {
      if (this.filterList.every(item => item.name != this.filterName)) {
        this.filterLoading = true
        const data = {
          name: this.filterName,
          content: JSON.stringify({
            userIds: this.userIds,
            stageIds: this.stageIds,
            productionName: this.productionName,
            selTaskStatus: this.selTaskStatus,
            finishStatus: this.finishStatus
          }),
          domain: 'myTssk',
          type: 'FILTER'
        }
        await postAction('/fontQueryCondition', [data])
        const res = await getAction('/fontQueryCondition', { domain: 'myTssk', type: 'FILTER' })
        this.filterList = res.data || []
        this.filterLoading = false
      } else {
        this.$message.error('收藏名称重复')
      }
    },
    async openPop() {
      this.filterLoading = true
      const res = await getAction('/fontQueryCondition', { domain: 'myTssk', type: 'FILTER' })
      this.filterList = res.data || []
      this.popoverVis = true
      this.filterLoading = false
    },
    async getFilterTypes() {},
    async starClick(item) {
      this.filterLoading = true
      await deleteAction(
        '/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids: [item.id || ''] })).toString()
      )
      const res = await getAction('/fontQueryCondition', { domain: 'myTssk', type: 'FILTER' })
      this.filterList = res.data || []
      this.filterLoading = false
    },
    compute(item, menuIndex, menuText) {
      if (!this.selectionRows.length) {
        this.$message.warning('请先勾选数据')
        return
      }
      if (menuText == '求和') {
        item.total = 0
        this.selectionRows.forEach(i => {
          item.total = item.total + (i[item.dataIndex] - 0)
        })
        console.log(this.$refs['statistics-' + menuIndex])
        this.$refs['statistics-' + menuIndex][0].style.opacity = '1'
      } else if (menuText == '平均') {
        let total = 0
        this.selectionRows.forEach(i => {
          total = total + (i[item.dataIndex] - 0)
        })
        item.total = (total / this.selectionRows.length).toFixed(2)
        this.$refs['statistics-' + menuIndex][0].style.opacity = '1'
      }
    },
    handleScroll(e) {
      document.getElementsByClassName('tabel-result-footer')[0].scrollLeft = e.target.scrollLeft
    },
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
      params.size = params.pageSize
      getAction(this.url.list, params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          res.data = res.data || res.data
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          this.dataSource = res.data.records || res.data
          this.dataSource.forEach(item => {
            item.totalScore = ''
            item.evalResult = {
              kpiValueErrors: []
            }
          })
          if (res.data.total) {
            this.ipagination.total = res.data.total - 0
          } else {
            this.ipagination.total = 0
          }
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
        this.loading = false
      })
    },
    prevClick() {
      this.$refs.calendarPanel.prevClick()
    },
    nextClick() {
      this.$refs.calendarPanel.nextClick()
    },
    changeViewTypeToWeek(type) {
      this.calendarType = type
      this.isWeek = type
    },
    setSkipLoading(skipLoading) {
      this.skipLoading = skipLoading
    },
    backSkip() {
      this.isSkip = false
      this.isAdmin = false
      const { positions, post, roles, userFile, id } = this.userInfo || {}
      this.queryParam.userIds = id
      this.userIds = [id]
      if (Array.isArray(positions)) {
        positions.forEach(item => {
          if (item.code === post) {
            this.name = item.name
          }
        })
      }
      if (roles) {
        roles.forEach(one => {
          if (one.roleCode == 'admin' || one.roleCode == 'boss' || one.roleCode == 'tongchou' || one.roleCode == 'hr') {
            this.isAdmin = true
          }
        })
      }
      if (!this.isAdmin) {
        this.workdayAvailableCount = userFile.workdayAvailableCount - 0
        this.workDays = userFile.workDays
      }
      this.getAllUsers()
      this.getStageList()
      getAction('/sys/user/get_user_file', { userId: this.queryParam.userIds })
        .then(res => {
          if (res.success) {
            this.workdayAvailableCount = res.data.workdayAvailableCount - 0
            this.workDays = res.data.workDays
            this.$refs.calendarPanel.addDayTitle()
          }
        })
        .catch(err => {})
    },
    setQueryParam(info) {
      this.queryParam.userIds = info.id
      this.userIds = [info.id]
      this.isSkip = info.isSkip
      getAction('/sys/user/get_user_file', { userId: this.queryParam.userIds })
        .then(res => {
          if (res.success) {
            this.isAdmin = info.type
            /* this.$refs.calendarPanel.isShow = false */
            this.workdayAvailableCount = res.data.workdayAvailableCount - 0
            this.workDays = res.data.workDays
            this.$refs.calendarPanel.addDayTitle()
          }
        })
        .catch(err => {})
    },
    setIsWorkEdit() {
      this.isWorkEdit = false
      let userInfo = this.userInfo || {}
      if (userInfo.userFile) {
        this.workDays = userInfo.userFile.workDays
        this.workdayAvailableCount = userInfo.userFile.workdayAvailableCount - 0
      }
    },
    async workEdit() {
      if (!this.isWorkEdit) {
        this.isWorkEdit = !this.isWorkEdit
      } else {
        let userInfo = this.userInfo || {}
        userInfo.userFile.workDays = this.workDays
        userInfo.userFile.workdayAvailableCount = this.workdayAvailableCount || 0
        let n1 = userInfo.userFile.workdayAvailableCount || 0
        if (userInfo.userFile.weekendAvailableCount !== undefined) {
          if (
            userInfo.userFile.workDays.every(item => {
              return item != 6 && item != 7 && item != 8
            })
          ) {
            userInfo.userFile.weekendAvailableCount = 0
          }
        }
        let n2 = userInfo.userFile.weekendAvailableCount || 0
        let arr = userInfo.userFile.workDays?.filter(item => {
          return item != 8
        })
        let length = arr ? arr.length : 0
        userInfo.userFile.weekAvailableCount = n1 * length + n2

        await putAction('/sys/user/edit_user_file', userInfo)
        try {
          this.$message.success('保存成功')
          window.location.reload(true)
          this.isWorkEdit = !this.isWorkEdit
        } catch (err) {
          console.log(err)
        }
      }
    },
    getTitle() {
      this.calendarDate = document.getElementsByClassName('fc-toolbar-title')[0]
        ? document.getElementsByClassName('fc-toolbar-title')[0].innerText
        : ''
      this.$nextTick(() => {
        let date = new Date()
        var Y = date.getFullYear() + ''
        var M = date.getMonth() + 1
        this.nowDate = Y + '年' + M + '月'
      })
    },
    kpiViewList(errorTypeScoreMap) {
      if (!errorTypeScoreMap) return []
      return Object.entries(errorTypeScoreMap)
        .map(([type, score]) => ({ type, score }))
        .filter(i => i.score > 0)
    },
    getDate(info) {
      this.dateInfo = info.dateInfo
      this.isWorkDay = info.isWorkDay
      this.isHoliDay = info.isHoliDay
      this.isSleep = info.isSleep
      console.log(info)
    },
    async setVacation() {
      if (this.dateInfo.length == 0) return
      const res = await postAction('/workSchedule/', {
        startDate: this.dateInfo.startStr,
        endDate: this.dateInfo.endStr,
        type: this.vacation == '0' ? null : this.vacation,
        userIds: this.userIds
      })
      if (res.code == 200) {
        this.$message.success('修改成功')
        this.$refs.calendarPanel.reloadWorkDay(res.data)
        this.vacation = undefined
        this.dateInfo = null
      }
    },
    workDaysChange() {
      if (
        this.workDays.some(item => {
          return item == -1
        })
      ) {
        this.selectDis = true
        this.workDays = [-1]
      } else {
        this.selectDis = false
      }
    },
    workCountChange() {},
    changeSelect() {
      this.$refs.calendarPanel.handleSelectOn()
      this.isCalendarCancel = !this.isCalendarCancel
      this.selectKeys = []
    },
    async handleTasks(stageId, priority) {
      this.visible = false
      let params = {
        operator: 'REJECTING',
        sourceTaskIds: [],
        targetStageId: stageId,
        priority
      }
      if (this.selectionRows.length > 0) {
        params.sourceTaskIds = this.selectionRows.map(item => item.taskId)
      } else {
        params.sourceTaskIds = this.selectKeys.map(item => item.taskId)
      }
      const res = await postAction('/tasks/batch/updateStatus', params)
      if (res.code == 200) {
        this.$message.success('驳回成功')
        this.rejectStages = []
        this.selectionRows = []
        this.selectedRowKeys = []
        this.selectKeys = []
        Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
          item.checked = false
        })
        this.searchQueryForm()
        this.$refs.calendarPanel.handleSelectOn()
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    async handleTaskFine() {
      this.visible = false
      let params = { operator: 'FINISHED', sourceTaskIds: [] }
      if (this.selectionRows.length > 0) {
        params.sourceTaskIds = this.selectionRows.map(item => item.taskId)
      } else {
        params.sourceTaskIds = this.selectKeys.map(item => item.taskId)
      }
      if (
        (this.selectionRows.length &&
          this.selectionRows.some(
            item =>
              item.useTranslationSystem &&
              (item.stageName == '翻译' || item.stageName == '校对' || item.stageName == '终审')
          )) ||
        (this.selectKeys.length &&
          this.selectKeys.some(
            item =>
              item.useTranslationSystem &&
              (item.stageName == '翻译' || item.stageName == '校对' || item.stageName == '终审')
          ))
      ) {
        this.$confirm({
          content: h => <div>请确认当前任务的工作已经全部完成，提前点完成/未完成点完成均会影响后续考核。</div>,
          okText: '是',
          cancelText: '否',
          onOk: async () => {
            const response = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
            if (response.code == 200) {
              if (response.data.productionList && response.data.productionList.length) {
                this.$refs.termListModal.open(response.data.productionList)
              } else {
                const res = await postAction('/tasks/batch/updateStatus', params)
                if (res.code == 200) {
                  upstatusDownloadFile(params.sourceTaskIds)
                  this.$message.success('已完成')
                  this.rejectStages = []
                  this.selectionRows = []
                  this.selectedRowKeys = []
                  this.selectKeys = []
                  Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
                    item.checked = false
                  })
                  this.searchQueryForm()
                  this.$refs.calendarPanel.handleSelectOn()
                } else {
                  this.$message.error(res.msg || res.message)
                }
              }
            }
          }
        })
      } else {
        if (this.selectionRows.some(row => !row.finishManuscriptFileCount && row.stageName == '制作修改')) {
          let message = ''
            this.selectionRows.forEach(row => {
              if (!row.finishManuscriptFileCount && row.stageName == '制作修改') {
                let text ='【'+ row.productOrder + '-' + row.productionName + '-' + row.chapterOrder + '-' + row.stageName+'】'
                message = message + text
              }
            })
          this.$confirm({
            content: h => (
              <div>
                {message}修改稿数量为0，是否确认完成？
              </div>
            ),
            okText: '是',
            cancelText: '否',
            onOk: async () => {
              this.skipLoading = true
              const response = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
              if (response.code == 200) {
                if (response.data.productionList && response.data.productionList.length) {
                  this.$refs.termListModal.open(response.data.productionList)
                  this.skipLoading = false
                } else {
                  const res = await postAction('/tasks/batch/updateStatus', params)
                  this.skipLoading = false
                  if (res.code == 200) {
                    this.rejectStages = []
                    this.selectionRows = []
                    this.selectedRowKeys = []
                    this.selectKeys = []
                    this.isCalendarCancel = false
                    Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
                      item.checked = false
                    })
                    if (res.data.noUpdateTasks.length) {
                      this.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                    } else {
                      upstatusDownloadFile(params.sourceTaskIds)
                      this.$message.success('已完成')
                      if (this.$refs.table) this.$refs.table.getTableData()
                      if (this.$refs.kanBan) this.$refs.kanBan.reloadData()
                    }
                    if (this.$refs.calendarPanel && res.data.updatedTasks.length) {
                      this.$refs.calendarPanel.handleSelectOn()
                      this.$refs.calendarPanel.changeStatusData(res.data.updatedTasks)
                    }
                  } else {
                    this.$message.error(res.msg || res.message)
                  }
                }
              }
            }
          })
          return
        }
        const response = await getAction('/tasks/checkTermsStatus?taskIds=' + params.sourceTaskIds.join(','))
        if (response.code == 200) {
          if (response.data.productionList && response.data.productionList.length) {
            this.$refs.termListModal.open(response.data.productionList)
          } else {
            const res = await postAction('/tasks/batch/updateStatus', params)
            if (res.code == 200) {
              upstatusDownloadFile(params.sourceTaskIds)
              this.$message.success('已完成')
              this.rejectStages = []
              this.selectionRows = []
              this.selectedRowKeys = []
              this.selectKeys = []
              Array.from(document.getElementsByClassName('ant-checkbox-input')).forEach(item => {
                item.checked = false
              })
              this.searchQueryForm()
              this.$refs.calendarPanel.handleSelectOn()
            } else {
              this.$message.error(res.msg || res.message)
            }
          }
        }
      }
    },
    visibleChange() {
      setTimeout(() => {
        this.visible = !this.visible
      }, 100)
    },
    async getRejectStages() {
      let stageId = ''
      let productId = ''
      console.log(this.selectKeys, this.selectionRows)
      if (this.selectionRows.length > 0) {
        stageId = this.selectionRows[0].stageId
        productId = this.selectionRows[0].productionId
      } else {
        stageId = this.selectKeys[0].stageId
        productId = this.selectKeys[0].productionId
      }
      const res = await getAction('/production/canRejectStage', { stageId, productId })
      try {
        if (res.code == 200) {
          this.rejectStages = res.data
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        this.$message.error(err)
      }
    },
    getKey(data) {
      if (data.checked) {
        this.selectKeys.push(data)
      } else {
        this.selectKeys.splice(
          this.selectKeys.findIndex(item => item.stageId == data.stageId),
          1
        )
      }
    },
    handleLinkTranslateLocalDownPage() {
      createAlink(this.translateMyLocalDownPage, '_blank')
    },
    handleLinkTranslateUploadServerPage() {
      createAlink(this.translateUploadserver, '_blank')
    },
    handleLinkTranslateMyDowntasks() {
      createAlink(this.translateMyDowntasksDetialPath, '_blank')
    },
    handleLinkTranslateMytasks() {
      createAlink(this.translateMytasksDetialPath, '_blank')
      // this.$router.path({ path: this.translateMytasksDetialPath })
    },
    handleLinkTasks(record) {
      if (record.stageName === '给稿') {
        this.$router.push({
          path: `${this.mytasksUploadPath}/${record.taskId}/${record.productionId}/${record.chapterId}`,
          query: {
            record: encodeURIComponent(JSON.stringify(record))
          }
        })
      } else {
        this.$router.push({
          path: `${this.translateEditorPath}/${record.taskId}/${record.productionId}/${record.chapterId}/${record.platformId}`
        })
      }
    },
    getAllUsers() {
      getAction('/sys/sysDepart/userListToSelect').then(res => {
        this.allUsers = Object.assign([], res.data)
        const { id } = this.userInfo || {}
        this.userIds = [id]
        this.searchQueryForm()
      })
    },
    searchQueryForm(data) {
      if (this.userIds.length) {
        this.queryParam.userIds = this.userIds.join(',')
      }
      if (this.stageIds.length) {
        this.queryParam.stageIds = this.stageIds.join(',')
      }
      if (this.selTaskStatus.length) {
        this.queryParam.taskStatusList = this.selTaskStatus
      }
      if (this.finishStatus.length) {
        this.queryParam.finishStatusList = this.finishStatus
      }
      this.queryParam.productionName = this.productionName
      if (this.viewType == 'list') {
        this.queryParam.needKpiView = true
        this.searchQuery()
      } else if (this.viewType == 'calendar') {
        this.queryParam.needKpiView = false
        this.popoverVis = false
        this.$nextTick(() => {
          this.$refs.calendarPanel.reloadData()
        })
      } else if (this.viewType == 'kanban') {
        this.$nextTick(() => {
          this.$refs.calendarPanel.reloadData()
        })
      }
    },
    getStageList() {
      getAction('/stage/stageListToSelect').then(({ success, data }) => {
        this.stageList = data
      })
    },

    changeViewType(viewType) {
      if (this.viewType == 'calendar') {
        this.startDate = this.queryParam.startDate = ''
        this.endDate = this.queryParam.endDate = ''
      }
      if (viewType != 'list') {
        this.queryParam.needKpiView = false
      } else {
        this.queryParam.needKpiView = true
      }
      if (this.viewType != viewType && viewType == 'list') {
        this.loadData()
      }
      this.viewType = viewType
      if (viewType == 'calendar') {
        this.isView = false
        this.$refs.calendarPanel.addDayTitle()
        this.$nextTick(() => {
          this.$refs.calendarPanel.reloadData()
        })
      }
      if (viewType == 'calendar-table') {
        this.viewType = 'calendar'
        this.isView = true
        this.$nextTick(() => {
          this.$refs.calendarPanel.reloadData()
        })
      }
      this.selectKeys = []
      this.selectedRowKeys = []
    },
    setSearchDate({ startDate, endDate }) {
      if (startDate) this.startDate = startDate
      if (endDate) this.endDate = endDate
      console.log('setSearchDate', { startDate, endDate })
      this.queryParam.startDate = startDate?.format('YYYY-MM-DD') || ''
      this.queryParam.endDate = endDate?.format('YYYY-MM-DD') || ''
      console.log({ 'this.queryParam': this.queryParam })
    },
    async getUser() {
      const res = await getAction(`/sys/user/list?id=${this.$route.params.userId}`)
      this.userInfo = res.data.records[0]
      this.otherShow = true
      const { positions, post, roles, userFile } = this.userInfo || {}
      if (Array.isArray(positions)) {
        positions.forEach(item => {
          if (item.code === post) {
            this.name = item.name
          }
        })
      }
      if (roles) {
        roles.forEach(one => {
          if (one.roleCode == 'admin' || one.roleCode == 'boss' || one.roleCode == 'tongchou' || one.roleCode == 'hr') {
            this.isAdmin = true
          }
        })
      }
      this.workdayAvailableCount = userFile.workdayAvailableCount - 0
      this.workDays = userFile.workDays || []
      this.getAllUsers()
      this.getStageList()
      this.getWorkNum()
    }
  },
  mounted() {
    this.color = this.$store.state.app.color
    setTimeout(() => {
      if (!this.isAdmin) this.getTitle()
    }, 50)
  },
  created() {
    this.getUser()
    this.getCheckList()
  }
}
</script>

<style lang="less" scoped>
.calendar-menu {
  display: inline-block;
  font-size: 12px;
  > div {
    display: flex;
    align-items: center;
    &:hover {
      color: var(--theme-color);
      cursor: pointer;
    }
    > span {
      margin-left: 3px;
    }
  }
}
.work-left {
  font-size: 12px;
  margin-left: 1vw;
  display: flex;
  align-items: center;
  .work-day {
    margin-left: 7px;
  }
  .work-day-num {
    margin-left: 20px;
  }
  .work-day-num-value,
  .work-day-value {
    text-decoration: underline;
    &:hover {
      color: var(--theme-color);
      cursor: pointer;
    }
  }
}
.work-right {
  order: 1;
  flex: 1;
  .iconfont {
    font-size: 22px !important;
    &:hover {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 1600px) {
  .work-day-num {
    display: none;
  }
  .work-day {
    display: none;
  }
  .work-day-num-value {
    margin-left: 15px;
  }
}
/deep/ .search-user-input {
  .ant-input {
    padding-right: 90px !important;
  }
}
.search-user-span:hover {
  cursor: pointer;
  color: var(--theme-color);
}
.search-user:hover {
  cursor: pointer;
  color: var(--theme-color);
}
.search-user,
.search-user-span {
  color: rgba(0, 0, 0, 0.25);
}
/deep/ .search-user svg {
  transform: rotate(0deg) !important;
}
.statistics {
  cursor: pointer;
  width: 100%;
  opacity: 0;
  background: #f5f7fa;
  &:hover {
    opacity: 1;
  }
}
/deep/ .ant-table-footer {
  padding-right: 0px !important;
  background-color: #fff !important;
}
.tabel-result-footer {
  white-space: nowrap;
  padding-left: 44px;
  overflow-x: scroll;
  > div {
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
  }
}
.tabel-result-footer::-webkit-scrollbar {
  height: 0;
}
/* /deep/ .ant-table-header-column {
  .ant-table-selection {
    display: none !important;
  }
} */
/deep/ .ant-select-selection-selected-value {
  img {
    display: none;
  }
}
/* /deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
} */
</style>
